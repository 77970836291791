export const getProxyRolesForUser = async (userid) => {
  const resp = await logFetchAJAX(getUrl(`/api/iam/users/${userid}/roles`), {
    method: "GET",
  })
  const rassocs = resp["rassocs"] || [];
  const out = {}
  rassocs.forEach(rassoc => {
    const restype = rassoc.resource_type || ""
    if (restype == "proxy") {
      const proxyName = rassoc.resource_id
      if (!(proxyName in out)) {
        out[proxyName] = []
      }
      out[proxyName].push(rassoc.role_name)
    }
  })
  return out 
}
